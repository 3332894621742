<template>
  <div class="mpWaybillList">
    <!-- <el-breadcrumb separator="/" style="margin: 10px 0px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>待开运单</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 表格区域 -->

    <div style="max-height:84.5vh">
      <!-- <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="小程序运单" name="1"></el-tab-pane>
        <el-tab-pane label="其他运单" name="2"></el-tab-pane>
      </el-tabs> -->
      <div>
        <div class="topbtns" style="display: flex;justify-content: space-between;">
          <div></div>
          <!-- <el-button icon="el-icon-download" type="primary" size="medium" @click="exportExcel">导出表格</el-button> -->
          <div style="margin-right: 30px;">
            <!-- <el-button  type="primary" size="medium" @click="getFriendAllWayBill">获取全部数据</el-button> -->
            <el-button type="success" size="medium" @click="showAccountDialogBox('accountInfo')">配置账号</el-button>
          </div>
        </div>

        <el-form class="searchForm" :model="searchForm" ref="searchForm">

          <div style="display: flex; flex-wrap: wrap;">
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="企业名称：" prop="keyword" label-width="120px">
                <el-input  @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.companyName" placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item label="司机手机号：" prop="keyword" label-width="120px">
                <el-input  @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.driverPhone" placeholder="请输入司机手机号">
                </el-input>
              </el-form-item>
            </div>
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="收款人手机号：" prop="keyword" label-width="120px">
                <el-input  @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.receivePhone" placeholder="请输入收款人手机号">
                </el-input>
              </el-form-item>
              <el-form-item label="车牌号：" prop="keyword" label-width="120px">
                <el-input  @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.carNumber" placeholder="请输入车牌号">
                </el-input>
              </el-form-item>
              <el-form-item label-width="10px">
                <div style="white-space:nowrap;">
                  <el-button type="primary" size="medium" @click="search('searchForm')" icon="el-icon-search">搜索</el-button>
                  <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm('searchForm')">清空</el-button>
                    <el-button type="primary" size="medium" :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="isShowSelect">更多选项</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
          <!-- <el-form-item label="运单号：" prop="keyword" label-width="130px">
          <el-input v-model="searchForm.wayBillID" placeholder="请输入运单号">
          </el-input>
        </el-form-item> -->
          <el-collapse-transition>
            <div style="display: flex; flex-wrap: nowrap;" v-show="showSelect">
              <el-form-item label="起始地：" label-width="120px">
                <el-input  @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.startAddress" placeholder="请输入起始地">
                </el-input>
              </el-form-item>
              <el-form-item label="到达地：" label-width="120px">
                <el-input  @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.arriveAddress" placeholder="请输入到达地">
                </el-input>
              </el-form-item>
              <el-form-item label="签收时间：" prop="keyword" label-width="120px">
                <el-date-picker @change="search" v-model="searchForm.time" type="datetimerange" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-collapse-transition>
        </el-form>
        <el-table  :data="tableData" v-loading="loading" :header-cell-style="{color: '#666', background: '#f0f0f0'}">
          <el-table-column fixed align="center" type="index" prop="index" label="序号" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="BillCode" label="运单号" width="200" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200"></el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200"></el-table-column> -->
          <!-- <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200"></el-table-column> -->
          <el-table-column align="center" prop="ShipperName" label="货主单位" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverMobileNo" label="司机手机号码" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverName" label="收款人姓名" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="DriverMobileNo" label="收款人手机号码" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="PlateNumber" label="车牌" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="Truck_NetWeight" label="装货净重(吨)" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LoadingAddress" label="起始地" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{scope.row.LoadingAddress}}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="AriveAddress" label="到达地" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{scope.row.AriveAddress}}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Qty" label="司机装货数量" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="Price" label="司机运输单价（人民币）" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{scope.row.Price | formatMoney}}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="TransportFee" label="运费" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{scope.row.TransportFee | formatMoney}}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{scope.row.ExpensesOfTaxation ? '￥' + scope.row.ExpensesOfTaxation : ''}}
              </div>

            </template>
          </el-table-column> -->
          <!-- <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{scope.row.TotalAmount ? '￥' + scope.row.TotalAmount : ''}}
              </div>
            </template>
          </el-table-column> -->

          <!-- <el-table-column align="center" prop="TotalTransCost" label="运输总成本" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{scope.row.TotalTransCost ? '￥' + scope.row.TotalTransCost : ''}}
              </div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column align="center" prop="TrailerNumber" label="挂车车牌" width="200"></el-table-column> -->
          <el-table-column align="center" prop="Sign_NetWeight" label="卸货净重(吨)" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="ShipperContacts" label="货主联系人" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="ShipperTel" label="货主联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="Name" label="承运商单位" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="Contacts" label="承运商联系人" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="ContactTel" label="承运商联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="Price" label="承运运输单价（人民币）" width="200">
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{scope.row.CarriagePrice | formatMoney}}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="LossRate" label="允许损耗率(‰)" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="TakeUnitName" label="提货单位" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="TakePhoneNo" label="提货联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="TakeAddress" label="提货地址" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="TakeContactsName" label="提货联系人" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnLoadUnitName" label="卸货单位" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnLoadContactsPhoneNo" label="卸货联系人手机号码" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnLoadAddress" label="卸货地址" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="UnLoadContactsName" label="卸货联系人" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="SignBillID" label="签收单号" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="AcceptanceCode" label="手工单号" width="200" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column align="center" prop="BillDatetime" label="单据时间" width="200"></el-table-column>
          <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="200"></el-table-column> -->
          <!-- <el-table-column align="center" prop="TaskStatus" label="运单状态" width="200">
           <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.TaskStatus == 3 ? 'success' : 'danger'"> {{scope.row.TaskStatus == 3  ? '已开票' : '未开票'}}</el-tag>
            </div>
          </template>
        </el-table-column> -->
        </el-table>
        <!--配置账号弹框-->
        <el-dialog class="vehicleType" :visible.sync="showAccountDialog" style="margin-top: 15vh;">
          <span slot="title" class="dialog-title">
            配置账号
          </span>
          <div class="body">
            <el-form class="addForm" :model="accountInfo" ref="accountInfo" :rules="addRule" label-width="80px">

              <el-form-item label="账号" prop="phone" style="margin-bottom: 20px;">
                <el-input autocomplete="off" v-model="accountInfo.phone" placeholder="请输入账号"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input autocomplete="new-password" v-model="accountInfo.password" type="password" placeholder="请输入密码"></el-input>
              </el-form-item>

            </el-form>
          </div>
          <span slot="footer">
            <el-button size="medium" @click="showAccountDialog = false">取消</el-button>
            <el-button type="primary" size="medium" @click="submitAccount">确认</el-button>
          </span>
        </el-dialog>
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import {
  _setOhterWayBillAccount,
  _getOhterWayBillAccount
} from "@/utils/storage";
import md5 from "js-md5";
import { mpAllBillDwon, filterFriendList, getFriendAllWayBill, setFriendAccount } from '@/api/waybill/otherAndAllBill/index'
export default {
  data() {
    var validator1 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入账号'));
      } else {
        callback();
      }
    };
    var validator2 = (rule, value, callback) => {
      if (!/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(.{6,})|(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(?=.*[`~@!#$%^&*()_\-+=<>?:\"{}|,.\/;'\\[\]])(.{6,})$/.test(value)) {
        callback(new Error('请填写必须包含字母,数字,长度不能少于6位的密码'));
      } else {
        callback();
      }
    };
    return {
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 账号信息
      accountInfo: {
        phone: '',
        password: ''
      },
      // 搜索表单
      searchForm: {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: '',// 时间
        //  type: '01', // 场景类型 默认为查找承运业务
        //  status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '' // 到达地
      },
      tableData: [], //表格数据
      addRule: {
        phone: [{ required: true, validator: validator1, trigger: 'blur' }],
        password: [{ required: true, validator: validator2, trigger: 'blur' }],

      },
      // 是否禁用提交按钮
      // showSubmit: false,
      loading: false,
      showSelect: true,
      showAccountDialog: false, // 配置账号弹框
      canGetData: false, // 为true的时候说明账号配置成功 可以获取数据
    }
  },

  methods: {
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect
    },
    //搜索
    search(formName) {
      if (!this.canGetData) {
        this.$message.warning('请先配置账号')
      } else {
        // this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //搜索前初始化page
        this.pagination.page = 1
        this.loading = true
        let params = {
          account: this.accountInfo.phone,
          pageSize: this.pagination.pagesize,
          pageIndex: this.pagination.page,
          //   taskType: this.searchForm.type,
          //   wayBillStatus: this.searchForm.status,
          driverMobileNo: this.searchForm.driverPhone,
          plateNumber: this.searchForm.carNumber,
          shipperName: this.searchForm.companyName,
          loadingAddress: this.searchForm.startAddress,
          ariveAddress: this.searchForm.arriveAddress,
          payeePhone: this.searchForm.receivePhone,
          //  taskSceneTypeID: 0,
          loadingDatetimeStart: this.searchForm.time[0],
          loadingDatetimeEnd: this.searchForm.time[1]
        }
        filterFriendList(params).then(res => {
          if (res.data.DataList) {
            this.tableData = res.data.DataList
            this.pagination.total = Number(res.data.TotalCount)
          }
        }).finally(() => {
          this.loading = false;
        })
      }
      //   }
      //   })
    },

    //清空
    resetForm(formName) {
      this.searchForm = {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: [],// 时间
        // type: '01', // 场景类型 默认为查找承运业务
        //  status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '' // 到达地
      }
      if (!this.canGetData) return
      this.loading = true
      let params = {
        account: this.accountInfo.phone,
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        //  taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverMobileNo: this.searchForm.driverPhone,
        plateNumber: this.searchForm.carNumber,
        shipperName: this.searchForm.companyName,
        loadingAddress: this.searchForm.startAddress,
        ariveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        //  taskSceneTypeID: 0,
        loadingDatetimeStart: this.searchForm.time[0],
        loadingDatetimeEnd: this.searchForm.time[1]
      }
      filterFriendList(params).then(res => {
        if (res.data.DataList) {
          this.tableData = res.data.DataList
          this.pagination.total = Number(res.data.TotalCount)
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    //导出excel
    exportExcel() {
      let params = {
        //  taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      mpAllBillDwon(params).then(res => {
        window.location.href = res.data
      })
    },

    //tbale选中改变
    tableSelectionChange(e) {
 
    },

    //分页控件页码change事件回调
    handleCurrentChange(e) {
      if (!this.canGetData) return
      this.loading = true
      let params = {
        account: this.accountInfo.phone,
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        driverMobileNo: this.searchForm.driverPhone,
        plateNumber: this.searchForm.carNumber,
        shipperName: this.searchForm.companyName,
        loadingAddress: this.searchForm.startAddress,
        ariveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        //  taskSceneTypeID: 0,
        loadingDatetimeStart: this.searchForm.time[0],
        loadingDatetimeEnd: this.searchForm.time[1]
      }
      filterFriendList(params).then(res => {
        if (res.data.DataList) {
          this.tableData = res.data.DataList
          this.pagination.total = Number(res.data.TotalCount)
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    //获取所有运单列表
    getAllBillList() {
      if (!this.canGetData) return
      let params = {
        account: this.accountInfo.phone,
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      this.loading = true;
      filterFriendList(params).then(res => {
        if (res.data.DataList) {
          this.tableData = res.data.DataList
          this.pagination.total = Number(res.data.TotalCount)
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    // 获取全部数据
    getFriendAllWayBill() {
      if (!this.canGetData) {
        this.$message.warning('请先配置账号')
      } else {
        let params = {
          account: this.accountInfo.phone
        }
        this.loading = true;
        getFriendAllWayBill(params).then(res => {
          this.getAllBillList()
        }).finally(() => {
          this.loading = false;
        })
      }

    },
    // 提交配置账号
    submitAccount() {
      this.$refs['accountInfo'].validate((valid) => {
        if (valid) {
          let params = {
            username: this.accountInfo.phone,
            password: md5(String(this.accountInfo.password)).toLocaleUpperCase()
          }
          setFriendAccount(params).then(res => {
            this.$message.success('配置成功')
            // 配置成功后存储账号到session，并获取列表
            _setOhterWayBillAccount(JSON.stringify(this.accountInfo))
            this.canGetData = true
            this.getFriendAllWayBill()
            this.showAccountDialog = false
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    // 打开配置账号弹框
    showAccountDialogBox(accountInfo) {
      this.showAccountDialog = true
      // 预防出现表单验证的提示
      if (!this.canGetData) {
 
        this.$nextTick(() => {
          this.$refs[accountInfo].clearValidate()
        })
      }
    },

  },
  created() {
    //获取全部运单列表
    // this.getAllBillList()

    if (_getOhterWayBillAccount()) {
      // 查看session 是否存在账号信息 如果存在就可以获取数据
      this.canGetData = true
      this.accountInfo = JSON.parse(_getOhterWayBillAccount())
      this.getFriendAllWayBill()
    }
  },

}
</script>

<style>

.vehicleType .el-dialog {
  top: -10%;
}
.v-modal {
  z-index: 2000 !important;
}
</style>

<style scoped lang="scss">
@import '../../../../../assets/style/variable.scss';
.mpWaybillList {
  .picArr1 {
    // padding-left: 30px;
    .title {
      margin-top: 30px;
      padding-bottom: 20px;
      padding-left: 30px;
      border-bottom: 1px solid #f5f5f5;
    }
    .upload {
      margin-left: 60px;
      margin-top: 30px;
    }
  }
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
  .el-form-item {
    margin-bottom: 14px;
  }
  .head {
    display: flex;
    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }
  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
      padding-top: 28px !important;
    }
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .uploadArea {
      margin-top: 20px;
      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .hint {
      display: flex;
      justify-content: center;
    }
    .choose {
      display: flex;
      justify-content: center;
    }
  }
  .btns {
    margin-bottom: 20px;
  }
  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }
  .addForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 60px;
    .el-form-item {
      width: 45%;
    }
  }
  .pagination {
    margin-top: 10px;
  }
}
</style>